<template>
  <div>
    <a-spin :spinning="showLoading" size="large">
      <el-table
        ref="tabInst"
        v-bind:class="{
          'w-table_moving': dragState.dragging,
          radio_table: checkType == 'radio'
        }"
        v-bind="$attrs"
        v-on="$listeners"
        :data="data"
        @selection-change="selectionChange"
        :summary-method="getSummaries || getSummariesFun"
        :cell-class-name="cellClassName"
        :header-cell-class-name="headerCellClassName"
      >
        <el-table-column
          v-if="showCheck"
          :reserve-selection="reserveSelection"
          fixed="left"
          type="selection"
          align="center"
          width="55"
          :selectable="checkSelectable"
        ></el-table-column>
        <el-table-column align="center">
          <template slot="header">
            <span class="table-header-th">库存</span>
            <header-pop title="库存">
              1.{{ dynamicMessage.inventory }}<br/>
            </header-pop>
          </template>
          <el-table-column
              v-for="(col, index) in column"
              :key="index"
              :fixed="col.fixed || false"
              :prop="col.prop"
              :label="col.label"
              :width="col.width"
              :align="col.align"
              :sortable="col.sortable"
              :min-width="col.minWidth"
              :type="col.type"
              :header-align="col.headerAlign"
              :column-key="index.toString()"
              :show-overflow-tooltip="!col.hiddenOverflowTooltip&&col.label!=='操作'"
          >
            <template slot="header" slot-scope="scope" :nouse="scope">
              <div
                  class="thead-cell"
              >
                <a class="table-header-th">{{ col.label }}</a>
                <span class="virtual" v-if="dragState.dragging"></span>
              </div>
            </template>
            <template slot-scope="scope">
              <div v-if="col.slot">
                <slot :name="col.slot" :row="scope.row" :column="scope.column" :$index="scope.$index"></slot>
              </div>
              <span class="td-span" v-else>{{ scope.row[col.prop] }}</span>
            </template>
          </el-table-column>
        </el-table-column>
        <slot name="handled" />
        <div slot="empty" class="dataNull">
          <div>
            <img src="../../../../assets/order/noList.png" alt />
            <p>暂无数据，请试试其他筛选条件~</p>
          </div>
        </div>
      </el-table>
      <pagination
        v-if="pagination"
        class="page-wrap"
        :pager="pagination"
        :bgType="2"
        @query="getData"
      />
    </a-spin>
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/index.css";
import HeaderPop from '../../components/HeaderPop'
export default {
  name: "InventoryTable",
  components:{
    HeaderPop
  },
  props: {
    data: {
      default: function() {
        return [];
      },
      type: Array
    },
    column: {
      default: function() {
        return [];
      },
      type: Array
    },
    header: {
      default: function() {
        return [];
      },
      type: Array
    },
    // 分页，不传不显示分页
    pagination: {
      type: Object
    },
    //显示合计的列
    showTotalColumns: {
      type: Array,
      default: () => []
    },
    //自定义合计计算方法
    getSummaries: {
      type: Function
    },
    //显示勾选
    showCheck: {
      type: Boolean,
      default: () => false
    },
    //单选或多选
    checkType: {
      type: String,
      default: () => "checkbox"
    },
    showLoading: {
      type: Boolean,
      default: () => false
    },
    //自动计算宽度
    autoWidth: {
      type: Boolean,
      default: () => true
    },
    //自定义是否可选
    checkSelectable: {
      type: Function,
      default: () => {
        return function() {
          return true;
        };
      }
    },
    //表格唯一标识
    unKey: {
      type: String,
      default: () => {
        return "";
      }
    },
    //记住选择行，搭配row-key使用
    reserveSelection: {
      type: Boolean,
      default: () => {
        return false;
      }
    }
  },
  data() {
    return {
      dynamicMessage: { // 动态获取提示信息汇总
        inventory: '', // 库存提示信息
        inventoryQuantity: '', // 库存量提示信息
      },
      dragState: {
        start: -9, // 起始元素的 index
        end: -9, // 移动鼠标时所覆盖的元素 index
        dragging: false, // 是否正在拖动
        direction: undefined // 拖动方向
      },
      // 记录左边的固定列的列数
      fixedCount: -1,
      formThead: [],
      tableWrapRef: null,
      scrollInv: null, //自动滚动div
      scroolerRight: 0, //左侧位置
      scroolerLeft: 0, //右侧位置
      changeInv: null,
      selRows: []
    };
  },
  mounted() {
    const that = this;
    // that.$nextTick(() => {
    //   let inv = setInterval(() => {
    //     if (this.$refs.tabInst) {
    //       this.$refs.tabInst.doLayout();
    //       clearInterval(inv);
    //     }
    //   }, 500);
    // });
    this.getMessage()
  },
  watch: {
    header: {
      handler: function(val, oldVal) {
        this.recoverySorter(val);
      },
      immediate: true
    },
    // "dragState.dragging": function(val) {
    //   if (val) {
    //     this.$nextTick(() => {
    //       document.body.className = "cursor_moving";
    //     });
    //   } else {
    //     document.body.className = "";
    //   }
    // },
    data: {
      handler: function(val) {
        if (this.autoWidth) {
          this.setTabelWidth();
        }
      },
      immediate: true
    }
  },
  methods: {
    // 获取动态提示
    getMessage() {
      const inventory =  this.$configTotal({
        route: this.$route.fullPath,
        id:'14909545633'
      })

      this.dynamicMessage = { // 动态获取提示信息汇总
        ...this.dynamicMessage,
        inventory: inventory ? inventory.msgDesc : '', // 库存
      }
    },
    selectionChange(selection) {
      const self = this;
      if (this.checkType == "checkbox") {
        this.selRows = selection;
      } else {
        if (selection.length > 1) {
          this.$refs.tabInst.clearSelection();
          const sel = selection.pop();
          this.$refs.tabInst.toggleRowSelection(sel);
          this.selRows = [sel];
        } else {
          this.selRows = selection;
        }
      }
      if (this.changeInv) {
        clearTimeout(this.changeInv);
        this.changeInv = null;
      }
      this.changeInv = setTimeout(() => {
        this.$emit("selection-change-once", self.selRows);
        this.changeInv = null;
      }, 300);
    },
    //记录表格列位置
    saveSorter() {
      const key = "table_header_" + this.$route.fullPath + this.unKey;
      localStorage.setItem(key, JSON.stringify(this.formThead));
    },
    //回复表格顺序
    recoverySorter(val) {
      const key = "table_header_" + this.$route.fullPath + this.unKey;
      let tempHeadr = localStorage.getItem(key);
      if (tempHeadr) {
        tempHeadr = JSON.parse(tempHeadr);
        if (tempHeadr.length == this.header.length) {
          this.formThead = tempHeadr;
          return;
        }
      }
      this.formThead = val;
    },
    // 当更列位置变化后,有的列没有指定宽度或最小宽度,交换后,发现列表头的siz会自动为span的宽度
    // 所以事后恢复为列位置变化前的那个宽度
    fixMinWidth() {
      const that = this;
      let item = null,
        colList = null,
        fixCount = that.fixedCount;

      if (fixCount < 0) {
        fixCount = this.getFixedCount();
      }
      for (let i = 0; i < that.formThead.length; i++) {
        item = that.formThead[i];
        if (!("minWidth" in item)) {
          if (!colList) {
            let colgroup = this.$el
              .querySelector(".el-table__header-wrapper")
              .querySelector("colgroup");
            colList = colgroup.children;
          }
          const currentCol = colList[i + fixCount];
          item.minWidth = +currentCol.width;
        }
      }
    },
    // 计算左边的固定列,有多少列
    getFixedCount() {
      //el-table__fixed
      const that = this;
      let fixedTable = that.$el.querySelector(".el-table__fixed");
      if (fixedTable) {
        // 查找所有不包含类.is-hidden的th
        let thList = fixedTable
          .querySelector(".el-table__fixed-header-wrapper")
          .querySelectorAll("th:not(.is-hidden)");
        that.fixedCount = thList.length;
      } else {
        that.fixedCount = 0;
      }
      return that.fixedCount;
    },
    //table.$emit('header-dragend', column.width, startLeft - startColumnLeft, column, event);
    //headerDragend(tNewWidth, tOldWidth, tColumn, tEvent) {},
    // 按下鼠标开始拖动
    handleMouseDown(e, column, tIdx) {
      const that = this;
      tIdx += that.getFixedCount();
      that.dragState.dragging = true;
      that.dragState.start = tIdx;

      that.fixMinWidth();
      // setTimeout(()=>{
      //   debugger
      // },1000)
      // 给拖动时的虚拟容器添加宽高,因为virtual是v-if ="dragState.dragging"的,所以没有那么展现
      that.$nextTick(() => {
        const table = that.$el,
          virtual = table.getElementsByClassName("virtual"),
          scrollLeft = table.querySelector(".el-table__body-wrapper")
            .scrollLeft;

        for (const item of virtual) {
          item.style.height = table.clientHeight - 1 + "px";
          item.style.width =
            item.parentElement.parentElement.clientWidth + "px";
          if (scrollLeft > 0) {
            //这里减10,是猜的,因为css 里面.el-table th .virtual的marginLeft也是-10
            item.style.marginLeft = -scrollLeft - 10 + "px";
          }
        }
      });

      document.addEventListener("mouseup", that.handleMouseUp);
      //获取table实例
      this.initAutoScroller();
    },
    //初始化自动滚动
    initAutoScroller() {
      const padding = 80;
      this.tableWrapRef = this.$refs.tabInst.$el.querySelector(
        ".el-table__body-wrapper"
      );
      const bound = this.tableWrapRef.getBoundingClientRect();
      const left = bound.x || bound.left;
      this.scroolerLeft = left + padding;
      this.scroolerRight = left + this.tableWrapRef.clientWidth - padding;
    },

    // 鼠标放开结束拖动
    handleMouseUp() {
      if (this.dragState.direction != undefined) {
        this.dragColumn(this.dragState);
      }

      // 初始化拖动状态
      this.dragState = {
        start: -9,
        end: -9,
        dragging: false,
        direction: undefined
      };
      document.removeEventListener("mouseup", this.handleMouseUp);
      this.clearScoller();
    },
    //清除自动滚动
    clearScoller() {
      clearInterval(this.scrollInv);
      this.scrollInv = null;
    },
    // 拖动中
    handleMouseMove(e, column, tIdx) {
      const self = this;
      if (this.dragState.dragging) {
        tIdx += this.getFixedCount();
        // 记录起始列
        const index = tIdx;
        if (index - this.dragState.start !== 0) {
          this.dragState.direction =
            index - this.dragState.start < 0 ? "left" : "right"; // 判断拖动方向
          this.dragState.end = index;
        } else {
          this.dragState.direction = undefined;
        }
        //控制滚动
        const clientX = e.clientX;
        if (clientX < this.scroolerLeft) {
          if (!this.scrollInv) {
            self.scrollInv = setInterval(() => {
              // debugger
              self.tableWrapRef.scrollLeft -= 6;
            }, 25);
          }
        } else if (clientX > this.scroolerRight) {
          if (!this.scrollInv) {
            self.scrollInv = setInterval(() => {
              self.tableWrapRef.scrollLeft += 6;
            }, 25);
          }
        } else {
          self.clearScoller();
        }
      } else {
        return false;
      }
    },

    // 拖动易位
    dragColumn({ start, end, direction }) {
      // fixCount,左边的固定列个数
      let fixCount = this.fixedCount,
        item = null;

      if (fixCount < 0) {
        fixCount = this.getFixedCount();
      }

      start = start - fixCount;
      end = end - fixCount;

      const tempData = [];
      //const left = direction === 'left'
      const left = direction === "left" ? -1 : 1;
      const min = left <= 0 ? end : start - 1;
      const max = left <= 0 ? start + 1 : end;

      let colList = null,
        currentIndex = null,
        currentCol = null;
      for (let i = 0; i < this.formThead.length; i++) {
        if (i === end) {
          currentIndex = start;
          item = this.formThead[start];
        } else if (i > min && i < max) {
          currentIndex = i + left;
          item = this.formThead[currentIndex];
        } else {
          currentIndex = i;
          item = this.formThead[i];
        }
        /*
                    if (!("minWidth" in item)) {
                      if (!colList) {
                        let colgroup = this.$el.querySelector(".el-table__header-wrapper").querySelector("colgroup");
                        colList = colgroup.children;
                      }
                      currentCol = colList[currentIndex + fixCount];
                      item.minWidth = currentCol.width;
                    }
                    */
        tempData.push(item);
      }
      this.formThead = tempData;
      this.saveSorter();
    },
    headerCellClassName({ column, columnIndex }) {
      const active =
        columnIndex === this.dragState.end
          ? `darg_active_${this.dragState.direction}`
          : "";
      const start = columnIndex === this.dragState.start ? `darg_start` : "";
      return `${active} ${start}`;
    },
    cellClassName({ column, columnIndex }) {
      return columnIndex === this.dragState.start ? `darg_start` : "";
    },
    getData() {
      this.$emit("query", this.pagination);
    },
    getSummariesFun(param) {
      // const showTotalColumns = []//控制显示的列
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        if (!this.showTotalColumns.includes(column.label)) {
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += " "; //单位
        } else {
          sums[index] = ""; //计算存在nan单位
        }
      });

      return sums;
    },
    getStrLen(str) {
      var realLength = 0,
        len = str ? str.length : 0,
        charCode = -1;
      for (var i = 0; i < len; i++) {
        charCode = str.toString().charCodeAt(i);
        if (charCode >= 0 && charCode <= 128) realLength += 1.2;
        else realLength += 1.8;
      }
      return realLength;
    },
    setTabelWidth() {
      //建立表格字符长度对象
      // debugger
      let keyWordLenObj = {};
      const columns = this.formThead;
      const tableData = JSON.parse(JSON.stringify(this.data));
      for (const DataItem of tableData) {
        for (const Datakey in DataItem) {
          //数字转字符串 方便处理及省略显示
          DataItem[Datakey] = DataItem[Datakey]
            ? DataItem[Datakey].toString()
            : "";
          if (keyWordLenObj[Datakey]) {
            //有key则 比大小后更新
            const len = this.getStrLen(DataItem[Datakey]) * 9;
            if (keyWordLenObj[Datakey] < len) {
              keyWordLenObj[Datakey] = len;
            }
          } else {
            //无key则添加
            keyWordLenObj[Datakey] = DataItem[Datakey]
              ? this.getStrLen(DataItem[Datakey]) * 9
              : 0;
          }
        }
      }

      let tableWidth = 0;
      for (const columnItem of columns) {
        //45筛选与边距 预留宽度
        let titleWidth;
        if (columnItem.sortable) {
          titleWidth = this.getStrLen(columnItem.label) * 8 + 68;
        } else {
          titleWidth = this.getStrLen(columnItem.label) * 8 + 48;
        }
        for (const key in keyWordLenObj) {
          if (columnItem.prop == key) {
            //表头宽度 32padding边距
            if (keyWordLenObj[key] + 46 < titleWidth) {
              //小于表头宽度
              columnItem.width = titleWidth;
            } else {
              //大于等于表头宽度
              if (keyWordLenObj[key] + 46 < 360) {
                //小于最大宽度
                columnItem.width = keyWordLenObj[key] + 46;
              } else {
                //最大宽度
                columnItem.width = 360;
              }
            }
            //控制是否显示溢出浮框
            if(keyWordLenObj[key]<1){
              columnItem.hiddenOverflowTooltip = true
            }
            tableWidth += columnItem.width;
          }
        }
      }
      // debugger;
    }
  }
};
</script>

<style lang="less" scoped>
.el-table .darg_start {
  background-color: #f3f3f3;
}
.el-table th {
  padding: 0;
}
/deep/.el-table {
  .el-table__body td{
    overflow: hidden;
    .td-span{
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  td {
    a {
      color: #1890ff;
      +button{
        margin-left: 14px;
      }
      +a{
        margin-left: 14px;
      }    }
    button{
      padding: 0 !important;
      +a{
        margin-left: 14px;
      }
      +button{
        margin-left: 14px;
      }
    }
  }
  th.is-leaf{
    background-color: white;
  }
}
.el-table th .virtual {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  margin-left: -10px;
  background: none;
  border: none;
}

/*竖线虚线*/
.el-table .el-table__header-wrapper th.darg_active_left .virtual {
  border-left: 2px dotted #666;
  border-color: #2196f3;
  z-index: 99;
}

.el-table th.darg_active_right .virtual {
  border-right: 2px dotted #666;
  z-index: 99;
}

.el-table .thead-cell {
  padding: 0;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  overflow: initial;
}

/*修复只能点击文字拖动*/
.el-table .thead-cell {
  width: calc(100% - 26px);
}

/*
.el-table .thead-cell:before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
*/
.w-table_moving.el-table th .thead-cell {
  cursor: move !important;
}

.w-table_moving {
  cursor: move !important;
}

.w-table_moving .el-table__fixed,
.w-table_moving .el-table__fixed-right {
  cursor: not-allowed;
}

/*.virtual{*/
/*  display: none!important;*/
/*}*/
.page-wrap {
  padding: 24px 10px 21px;
  padding-right: 0;
}

.table-header-th {
  color: #262626;
}
</style>
<style>
.cursor_moving {
  cursor: move !important;
}

.el-table th.gutter {
  display: table-cell !important;
}

.el-table__empty-block {
  width: 100% !important;
}
.el-table .descending .sort-caret.descending {
  border-top-color: #00aaa6;
}

.el-table .ascending .sort-caret.ascending {
  border-bottom-color: #00aaa6;
}

.radio_table .el-table__header .el-checkbox {
  display: none;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #00aaa6;
  border-color: #00aaa6;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #00aaa6;
}

.el-checkbox__inner:hover {
  border-color: #00aaa6;
}

.el-checkbox.is-bordered.is-checked {
  border-color: #00aaa6;
}

.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #00aaa6;
}
.is-right .thead-cell {
  align-items: flex-end !important;
}
</style>
<style lang="less" scoped>
.dataNull {
  margin: 0 auto;
  margin-bottom: 24px;
  width: 100%;
  height: 340px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 160px;
    height: 120.24px;
    margin-bottom: 16px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: #777777;
    line-height: 20px;
  }
}
.icon-wentitishi{
  color:#aaa;
  margin-left: 4px;
  font-size: 14px;
}
</style>
