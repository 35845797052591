import {
  getInvChangeEverydayReport,
  getInvCurrMonthReport,
  exportStock,
  getInventoryTurnover
} from "./api.js";
import Util from "@/utils/utils";
import InventoryTable from "./components/InventoryTable";
import { orangeSymbol, greenSymbol, blueSymbol } from "../components/images";
import moment from "moment";
import InventoryFormFiltrate from "./components/InventoryFormFiltrate";

export default {
  components: {
    InventoryTable,
    InventoryFormFiltrate
  },
  data() {
    return {
      expLoading: false,
      pageLoadFlag: false,
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/reports/inventory",
          name: "inventory",
          title: "库存报表"
        }
      ],
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10
      },
      count: {
        totalOccQty: 0,
        totalActQty: 0,
        totalAvaQty: 0
      },
      columns: [
        {
          label: "日期",
          prop: "dateTime",
          width: 200
        },
        {
          label: "实际库存量",
          prop: "inventory",
          width: 200
        },
        {
          label: "库存周转(天)",
          prop: "turnoverDays"
        }
      ],
      data: [],
      terms: {},
      showLoading: false,
      chartData: {
        date: [],
        in: [],
        out: [],
        net: []
      },
      currentPreviousDay:''
    };
  },
  created() {
    // this.getData();
  },
  mounted() {
    this.getYesterday()
  },
  methods: {
    getYesterday() {
      let day1 = new Date();
      day1.setTime(day1.getTime()-24*60*60*1000);
      this.currentPreviousDay = day1.getFullYear()+"/" + (day1.getMonth()+1) + "/" + day1.getDate();
    },
    initChart() {
      const line = this.$echarts.init(this.$refs.lineChart);
      const option = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(38,38,38,0.69)",
          formatter: '{b}<br/><span style="display:inline-block;margin-right:5px;width:10px;height:10px;border-radius: 50%;' +
              'background: #5B8FF9;"></span>{a0}: {c0}台' +
              '<br/><span style="display:inline-block;margin-right:5px;width:10px;height:10px;border-radius: 50%;' +
              'background: #84E2BC;"></span>{a1}: {c1}台' +
              '<br/><span style="display:inline-block;margin-right:5px;width:10px;height:10px;border-radius: 50%;' +
              'background: #FFA767;"></span>{a2}: {c2}台',
          extraCssText: "min-width:140px!important;text-align: left;",
          borderWidth: 0,
          textStyle: {
            color: "#FFFFFF",
            fontWeight: 400,
            lineHeight: 20
          }
        },
        legend: {
          data: ["入库数量", "出数量库", "库存净数量"],
          selectedMode: false,
          right: 24,
          icon: "rect",
          itemWidth: 8,
          itemHeight: 8
        },
        grid: {
          left: "2%",
          right: "24",
          bottom: "5%",
          top: "12%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.chartData.date,
          axisLine: {
            lineStyle: {
              color: "#aaa"
            }
          },
          axisLabel: {
            textStyle: {
              color: "#777"
            }
          }
        },
        yAxis: {
          type: "value",
          axisTick: {
            //y轴刻度线
            show: false
          },
          axisLine: {
            //y轴
            show: false
          }
        },
        series: [
          {
            name: "入库数量",
            type: "line",
            data: this.chartData.in,
            smooth: true,
            showSymbol: false,
            symbolSize: 8,
            symbol: blueSymbol,
            color: "#5B8FF9",
            itmStyle: {
              color: "#5B8FF9"
            },
            lineStyle: {
              color: "#5B8FF9",
              shadowColor: "#5b8ff926",
              shadowBlur: 1,
              shadowOffsetX: 8
            },
            emphasis: {
              symbol: "circle",
              symbolSize: 4
            }
          },
          {
            name: "出数量库",
            type: "line",
            data: this.chartData.out,
            smooth: true,
            showSymbol: false,
            symbolSize: 8,
            symbol: greenSymbol,
            color: "#5AD886",
            itmStyle: {
              color: "#5AD886"
            },
            lineStyle: {
              color: "#5AD886",
              shadowColor: "#02c49126",
              shadowBlur: 1,
              shadowOffsetX: 8
            }
          },
          {
            name: "库存净数量",
            type: "line",
            data: this.chartData.net,
            smooth: true,
            showSymbol: false,
            symbolSize: 8,
            symbol: orangeSymbol,
            color: "#FFA767",
            itmStyle: {
              color: "#FFA767"
            },
            lineStyle: {
              color: "#FFA767",
              shadowColor: "#ffa76726",
              shadowBlur: 1,
              shadowOffsetX: 8
            }
          }
        ]
      };
      line.setOption(option);
    },
    searchFun(arg) {
      this.terms = arg;
      this.pager.pageNo = 1;
      this.getData();
    },
    exportFile() {
      // this.expLoading = true;
      // const data = {
      //   cisCode: this.$store.state.user.userInfo.customer.cisCode,
      //   _loading: true,
      //   terms: {
      //     model: "",
      //     colour: "",
      //     warehouseId: "",
      //     invStatusId: "",
      //     isLock: "",
      //     dealerMaterialGroupFlag: "",
      //     materialGroupCode: "",
      //     materialGroupName: "",
      //     gicWarehouseType: "",
      //     invStatusType: "",
      //     invType: "",
      //     bigQtyMin: "",
      //     ...this.terms
      //   },
      //   page: {
      //     pageNo: 1,
      //     pageSize: 300000,
      //     sortName: this.pager.sortName,
      //     sortOrder: this.pager.sortOrder
      //   },
      //   loginType: "CS"
      // };
      // const url = this.taoji?'exportInventoryProductList&type=1':'exportInventoryList&type=1'
      // exportStock(data,url).then(res => {
      //   Util.blobToJson(res.data).then(content=>{
      //     if(content&&content.msg=='success'){
      //       this.$message.success('下载成功，请到下载中心查看!')
      //     }
      //   }).catch(err=>{
      //     Util.downloadFile(res.data,'我的库存.xls')
      //   }).finally(()=>{
      //     this.expLoading = false;
      //   })
      // }).catch(err=>{
      //   this.expLoading = false;
      // });
    },
    getData() {
      const data = {
        userAccount: this.$store.state.user.userInfo.customer.cisCode,
        terms: {
          date: this.terms.month,
          gicWarehouseType: this.terms.gicWarehouseType,
          materialGroupCode: this.terms.matklCode
        },
        page: {
          pageNo: 1,
          pageSize: 10
        },
        loginType: "CS"
      };
      this.showLoading = true;
      getInvChangeEverydayReport(data).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        data.report = data.report || [];
        this.chartData = {
          date: data.report.map(it => it.date),
          in: data.report.map(it => it.in),
          out: data.report.map(it => it.out),
          net: data.report.map(it => it.net)
        };
        this.initChart();
      });
      getInventoryTurnover({
        month: this.terms.month.substr(0, 7).replace('-', ''),
        operatorCode: this.$store.state.user.userInfo.customer.cisCode,
        reSellCode: '',
        matklCode: this.terms.matklCode
      })
        .then(res => {
          const start = (this.pager.pageNo - 1) * 40;
          data.report = (res.data.result && res.data.result.mx2) || [];
          this.data = data.report.slice(start, start + this.pager.pageSize);
          if(this.data){
            this.data=this.data.map((val)=>{
              val.turnoverDays = Number(val.turnoverDays).toFixed(2)
              return val
            })
          }
        })
        .finally(() => {
          this.showLoading = false;
        });
    }
  }
};
