import dmshttp from "@/utils/dmsrequest";
import http from "@/utils/request";

//库存报表
export function getInvChangeEverydayReport(data) {
    return dmshttp({
        url: 'invChangeEverydayReport',
        data
    });
}
//库存表格
export function getInvCurrMonthReport(data) {
    return dmshttp({
        url: 'invCurrMonthReport',
        data
    });
}
//库存表格
export function getInventoryTurnover(data) {
    return http({
        method: "post",
        url: "/report/inventoryTurnover.nd",
        contentType: "application/json;charset=UTF-8",
        type: 'stringfy',
        data
    });
}
//获取分销商
export function fxDictCisCode(data) {
    return http({
        method: "post",
        url: "/comm/queryOrg.nd",
        data
    });
}
//获取分销商
export function queryMatkl(data) {
    return http({
        method: "post",
        url: "/comm/queryMatkl.nd",
        data
    });
}
//我的库存列表
export function findBaseData(data) {
    return dmshttp({
        url: "findBaseData",
        data
    });
}